import styled from "styled-components";

const QuestionWrapper = styled.div`
  padding: 1.2rem 2rem 0.5rem 2rem;
  background:  ${({theme}) => theme.colors.lightGrey}30;
  border-radius: ${({theme}) => theme.borderRadius2};
`

const QuestionHeader = styled.div`
  padding: 1rem 0;
  font-weight: 600;

  > p {
    font-size: small;
    font-weight: lighter;
    // color: ${({theme}) => theme.colors.brandTwo};
  }

`

const QuestionBody = styled.div`
  margin-bottom: 2rem;

  > div {
    padding: 0.5rem 0;
  }
`


const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`


// Radio button input
interface RadioInputProps {
    selected?: boolean
}

const RadioInput = styled.div<RadioInputProps>`
  cursor: pointer;
  padding: 1rem 0.3rem;
  margin-right: 1rem;
  border-radius: ${({theme}) => theme.borderRadius2};

  ${({selected, theme}) => {
    if (selected) {
      return `
      border: 2px solid ${theme.colors.brandOne}50;
      background: ${theme.colors.brandOne}95;
      color: ${theme.colors.white};
      font-weight: 700;`
    } else {
      return `
      border: 2px solid ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.black};`
    }
  }}

  display: flex;
  flex-direction: row;
  align-items: center;

  input[type='radio'] {
    height: 20px;
    width: 20px;
    cursor: inherit;
  }

  > div {
    margin-left: 0.8rem;
    cursor: inherit;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: left;
  }
`


// Yes-No-Questions
const BoolQuestionInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  input {
    margin-right: 1rem;
  }
  
  > div {
    padding: 1rem 1.1rem;
  }
`
const DateSelectInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > p {
    margin: 0 1rem;
  }
`

// Country question
const CountryContainer = styled.div`
  padding: 0.5rem 0;
  display: flex;
`

interface CountryWrapperProps {
    light?: boolean
}

const CountryWrapper = styled.div<CountryWrapperProps>`
  padding: 0.5rem 1rem 0.8rem 1rem;
  margin: 0.2rem 0.5rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.brandTwo};
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  vertical-align: center;
  height: 3.5rem;

  ${({light, theme}) =>
          light &&
          `
            background-color: ${theme.colors.white};
            color: ${theme.colors.black};
    `}
  > img {
    height: 100%;
    display: block;
    padding: 0.3rem 0 0;
    margin-left: 0.5rem;
  }
`
const SuggestionsContainer = styled.div`
  border: 2px solid ${({theme}) => theme.colors.lightGrey};
  border-radius: ${({theme}) => theme.borderRadius1};
  padding: 0.5rem 1rem;
  display: flex;
`

const LikertOptionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

interface LikertOptionProps {
    selected?: boolean
}

const LikertOption = styled.div<LikertOptionProps>`
  background: white;
  height: 50px;
  //flex: 1 1 0;
  ${({selected, theme}) => {
    if (selected) {
      return `
      border: 2px solid ${theme.colors.brandOne}50;
      background: ${theme.colors.brandOne}95;
      color: ${theme.colors.white};
      font-weight: 700;`
    } else {
      return `
      border: 2px solid ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.black};`
    }
  }}
  cursor: pointer;
  padding: 1rem 0.3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  min-width: 60px;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  
  > div {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
    
    > div {
    }
  }
`

export {
    QuestionWrapper,
    QuestionHeader,
    QuestionBody,
    CountryContainer,
    CountryWrapper,
    SuggestionsContainer,
    BoolQuestionInputContainer,
    DateSelectInputContainer,
    RadioInput,
    OptionContainer,
    LikertOption,
    LikertOptionContainer
}
