import {Survey, SurveyStage} from "../../types/types";
import {
    Button,
    CenteredContainer,
    ColoredSpan,
    Divider,
    Heading2,
    Heading3,
} from "../../styled";
import {
    AttributeContainer,
    Explanation,
    ExplanationHeader,
    ExplanationWrapper
} from "../stage_conjoint/conjointStageStyles";

interface Props {
    survey: Survey | null,
    setSurveyStage: (stage: SurveyStage) => void
}



const Component: React.FC<Props> = ({survey, setSurveyStage}) => {

    return (
        <CenteredContainer>
            <div>
                <Heading2>
                    Setting of the survey
                </Heading2>

                <div>
                    <ExplanationWrapper>
                        <>
                            <Heading3>
                                Overview
                            </Heading3>
                            <p>
                                During this experiment, you are asked to select between different
                                providers for international financial transactions. Such transactions
                                usually come with some kind of overhead in terms of transaction fees
                                or time and it requires some time to onboard to such a service, i.e.,
                                sign up and get verified.
                            </p>
                            <p>
                                Current offerings vary in a number of such attributes. We want to know
                                how you would decide between different providers in a situation where
                                you are signed up for one provider and want to switch to another one.
                            </p>
                            <p>
                                For the experiment on hand, think of typical transactions volumes of
                                around 500 USD a month.
                            </p>

                            <Divider height={1}/>

                            <Heading3>
                                Attribute descriptions
                            </Heading3>
                            <p>
                                Below, you find a description of the attributes that describe each
                                service that occurs in the survey. Please take your time to read
                                them carefully.
                            </p>
                            {
                                survey && survey.config.attributes.map((attribute, idx) => {
                                    return (
                                        <Explanation key={idx}>
                                            <ExplanationHeader>
                                                <div>
                                                    <p>
                                                        <ColoredSpan>
                                                            <b>{attribute.name}</b>
                                                        </ColoredSpan>
                                                        <br/>
                                                        <span>{attribute.description}</span>
                                                    </p>
                                                </div>
                                            </ExplanationHeader>
                                            <Divider height={0.1}/>
                                            {
                                                attribute.values.map((v) => {
                                                    return (
                                                        <AttributeContainer>
                                                            <div>
                                                                <b>{v.value}:</b>
                                                            </div>
                                                            <div>
                                                                {v.explanation}
                                                            </div>
                                                        </AttributeContainer>
                                                    )
                                                })
                                            }
                                        </Explanation>
                                    )
                                })
                            }
                        </>
                    </ExplanationWrapper>
                </div>

                <Divider height={5}/>
                <Button primary={true} onClick={() => setSurveyStage(SurveyStage.SWITCHING)}>
                    Understood
                </Button>
            </div>
        </CenteredContainer>
    )

}


export default Component
