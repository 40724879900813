import {Question} from "../survey/questionnaire/types";

enum SurveyStage {
    CONSENT = "CONSENT",
    SETTING = "SETTING",
    CONJOINT = "CONJOINT",
    SWITCHING = "SWITCHING",
    DEMOGRAPHICS = "DEMOGRAPHICS",
    FINISHED = "FINISHED"
}

interface Survey {
    survey_id: string,
    stage: SurveyStage,
    config: Config,
    answers: Answers
}

interface Config {
    attributes: Attribute[],
    choice_sets: [number[], number[]][]
    switching_sets: [number[], number[]][]
}

interface Answers {
    choices: [number, number, number][] // [choice_set_idx, answer_option_idx, timestamp]
    switching_decisions: [number, number, number][] // [switching_set_idx, switched_at_option_idx, timestamp]
    demographic_questions: Question[] // the questions completed with answers
    feedback: {
        feedback_stage: number,
        understandability: number, // Likert scale, 1 to 5
        comment: string
    }[]  // [feedback_stage, feedback]
}

interface Attribute {
    name: string,
    short: string,
    description: string,
    values: { value: string, explanation: string }[]
}


// tx_fees: number, //[0, 5.99],
// tx_time: number, //[1, 5],
// tx_privacy: string,  // ["high", "low"],
// onboarding_time: number,  // [1, 3],
// ownership: string, //["yes", "no"],
// community: string // ["strong", "weak"]



export {
    SurveyStage
}


export type {
    Survey,
    Config,
    Answers,
    Attribute
}
