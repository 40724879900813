import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Heading1 = styled.h1`
  position: relative;
  display: inline-block;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: ${({theme}) => theme.colors.black};

  span#icon {
    position: absolute;
    top: -6px;
    right: -3rem;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: ${({theme}) => theme.colors.red};
    border-radius: 10px;
    padding: 2px 6px;
    cursor: pointer;
  }

  @media (max-width: 1300px) {
    font-size: 3.5rem;
  }

  @media (max-width: 800px) {
    font-size: 3rem;
  }
`;

const Heading2 = styled.h2`
  font-family: ${({theme}) => theme.fontFamily2};
  font-size: 3.2rem;
  font-weight: 500;
  color: ${({theme}) => theme.colors.black};

  @media (max-width: 1300px) {
    font-size: 3rem;
  }

  @media (max-width: 800px) {
    font-size: 2.5rem;
  }

  @media (max-width: 500px) {
    font-size: 2.2rem;
  }
`;

interface Heading3Props {
    margin?: boolean;
    expand?: boolean;
}

const Heading3 = styled.h3<Heading3Props>`
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: ${({theme}) => theme.colors.black};

  ${(props) =>
          props.margin &&
          `
        margin: 6rem 0 3rem 0;
    `}

  ${(props) =>
          props.expand &&
          `
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            img {
                width: 1rem;
                transform: rotateZ(90deg);
            }
    `} @media(max-width: 1300 px) {
  font-size: 2rem;
}
`;

interface Heading4Props {
    dashboard?: boolean;
}

const Heading4 = styled.h4<Heading4Props>`
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1.7rem;
  color: ${({theme}) => theme.colors.black};

  @media (max-width: 1100px) {
    font-size: 1.6rem;
  }

  ${(props) =>
          props.dashboard &&
          `
        color: ${props.theme.colors.lightGrey};
        font-size: 1.8rem !important;
        font-weight: 500;
        margin-top: 6rem;
        margin-bottom: 3rem;
    `}
`;

const Heading5 = styled.h5`
  color: ${({theme}) => theme.colors.black};
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: capitalize;

  img {
    height: 2rem;
    margin: 0 1rem;
  }

  @media (max-width: 800px) {
    font-size: 2rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.colors.white};
  text-decoration: none;
  font-weight: 500;
  transition: 0.2s opacity;

  &:hover {
    opacity: 0.75;
  }
`;

interface ButtonProps {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    transparent?: boolean;
    dark?: boolean;
    error?: boolean;
}

const Button = styled.button<ButtonProps>`
  position: relative;
  outline: none;
  border: none;
  border-radius: ${({theme}) => theme.borderRadius1};
  background-color: ${({theme}) => theme.colors.white};
  border: none;
  color: ${({theme}) => theme.colors.black};
  padding: 1.4rem 2.2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s opacity;

  ${({theme, primary}) =>
          primary &&
          `
            background-color: ${theme.colors.brandOne};
            color: ${theme.colors.white};
    `}
  ${({theme, secondary}) =>
          secondary &&
          `
            background-color: ${theme.colors.brandTwo};
            color: ${theme.colors.white};
    `}
  ${({theme, tertiary}) =>
          tertiary &&
          `
            background-color: ${theme.colors.brandThree}DD;
            color: ${theme.colors.white};
    `}
  ${({theme, transparent}) =>
          transparent &&
          `
            background-color: ${theme.colors.lightGrey}20;
            color: ${theme.colors.white};
    `}
  ${({theme, dark}) =>
          dark &&
          `
            background-color: ${theme.colors.darkGrey};
            color: ${theme.colors.white};
    `}
  ${({theme, error}) =>
          error &&
          `
            background-color: ${theme.colors.red}B0;
            color: ${theme.colors.white};
    `}
  
  img {
    // width: 1.5rem;
    margin-right: 1rem;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  @media (max-width: 1000px) {
    padding: 1.2rem 2rem;
  }
`;

const ButtonSmall = styled(Button)`
  font-size: 1.5rem;
  border-radius: ${({theme}) => theme.borderRadius1};
  background-color: ${({theme}) => theme.colors.white}30;
  color: ${({theme}) => theme.colors.white};
  font-weight: 500;
  padding: 1rem 1.5rem;
  margin-top: 2rem;
`;

const ColoredSpan = styled.span`
  color: ${({theme}) => theme.colors.brandOne};
`

export {Heading1, Heading2, Heading3, Heading4, Heading5, StyledLink, Button, ButtonSmall, ColoredSpan};
