import styled from "styled-components";

const Questionnaire = styled.div`
  max-width: 770px;
  margin: 2rem auto 0 auto;
  
  > h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
`

const QuestionnaireHeader = styled.div`
  padding: 1rem 0;
  color: ${({theme}) => theme.colors.brandOne};
  font-weight: 450;
`

const QuestionnaireBody = styled.div`
  // border: 3px solid ${({theme}) => theme.colors.black};
  background: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius2};
  padding: 3rem;
`

const QuestionnaireFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`


const SectionHead = styled.div`
  padding-bottom: 1rem;

  > h2 {
    text-align: left;
  }
`

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const FinishModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({theme}) => theme.colors.darkGrey}95;
  backdrop-filter: blur(0px);
  
  > div {
    max-width: 500px;
    margin: 30vh auto 0 auto;
    background: ${({theme}) => theme.colors.white};
    border-radius: ${({theme}) => theme.borderRadius2};
    padding: 2rem;
    
    > div:first-child {
      margin-bottom: 3rem;
    }
    
    > div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      > div:first-child {
        margin: 0 3rem 0 0;
      }
      > div:last-child {
        background: ${({theme}) => theme.colors.brandTwo};
      }
    }
  }
`

export {
    Questionnaire,
    QuestionnaireHeader,
    QuestionnaireBody,
    QuestionnaireFooter,
    SectionHead,
    SectionBody,
    FinishModal
}
