import {apiUrl} from "./config";

const finishSurvey = async (survey_id: string): Promise<void> => {
    try {
        const res = await fetch(apiUrl + '/survey/' + survey_id + "/finish", {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });

        if (!res.ok) throw new Error();

        let answer =  await res.text();
        window.location.href = answer
    } catch (e) {
        throw new Error();
    }
};

export default finishSurvey;
