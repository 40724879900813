import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import {ThemeProvider} from "styled-components";
import ErrorBoundary from "./components/misc/ErrorBoundary";
import theme from "./style/theme";
import GlobalStyle from './style/GlobalStyle';
import Protected from "./components/auth/Protected";
import BadLogin from "./components/auth/BadLogin";
import SurveyComponent from "./components/survey/SurveyComponent";
import TokenHandler from "./components/auth/TokenHandler";
import IndexComponent from "./components/index/IndexComponent";
import ReturnComponent from "./components/return/ReturnComponent";

function App() {

    localStorage.setItem('token', 'empty')

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            <ErrorBoundary>
                <Router>
                    {/*<Navbar/>*/}
                    <Routes>
                        <Route path="/darkmagic/:token/:base64redirect" element={
                            <TokenHandler />
                        }/>

                        <Route path="/" element={
                            <IndexComponent/>
                        }/>

                        <Route path="/survey/:survey_id" element={
                            <Protected><SurveyComponent/></Protected>
                        }/>

                        <Route path="/survey/return/:source" element={
                            <Protected><ReturnComponent/></Protected>
                        }/>

                        <Route path={"/error"} element={<BadLogin/>}/>
                    </Routes>
                </Router>
            </ErrorBoundary>
        </ThemeProvider>

    );
}

export default App;
