import ErrorComponent from '../misc/Error';
import {useEffect} from "react";
import logout from "../../util/logout";

const BadLogin = () => {
    // useEffect(() => {
    //     logout();
    // }, []);

    return (
        <ErrorComponent message={"You are not authorized"} fullScreen={true}/>
    );
};

export default BadLogin;
