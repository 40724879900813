import React from 'react';
import { ErrorWrapper } from '../styled';

interface Props {
    message: string | null;
    fullScreen?: boolean;
}

const Error: React.FC<Props> = ({ message, fullScreen }) => (
    <ErrorWrapper fullScreen={fullScreen}>
        <span>💩</span>
        <p>{message}</p>
    </ErrorWrapper>
);

export default Error;
