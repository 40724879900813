import styled from "styled-components";

const FinishedWrapper = styled.div`
  max-width: 770px;
  margin: 100px auto 0 auto;
  padding: 3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.lightGrey}20;
`

const ImgWrapper = styled.div`
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.white};
`

export {
    FinishedWrapper,
    ImgWrapper
}
