import styled from 'styled-components';

const ExplanationWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;

  p {
    color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.lightGrey}80;
    height: 2.2rem;
    width: 2.2rem;
    border-radius: 50%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    padding: -1px -1px 0 0;
    cursor: pointer;
  }

  div {
    position: absolute;
    top: 3rem;
    right: 0;
    width: 20rem;
    padding: 1.5rem;
    line-height: 1.3;
    font-size: 1.4rem;
    font-weight: 400;
    color: ${({theme}) => theme.colors.white};
    border-radius: ${({theme}) => theme.borderRadius1};
    background-color: ${({theme}) => theme.colors.black};
    box-shadow: ${({theme}) => theme.boxShadow1};
  }

  @media (max-width: 1300px) {
    top: 1.5rem;
    right: 1.5rem;
  }
`;

const NewBadgeWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 6rem;
  z-index: 1;

  p {
    color: ${({theme}) => theme.colors.black};
    background-color: ${({theme}) => theme.colors.brandThreeAccent1};
    height: 2.2rem;
    width: 5.2rem;
    border-radius: 20px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    padding: -1px -1px 0 0;
  }

  @media (max-width: 1300px) {
    top: 1.5rem;
    right: 6rem;
  }
`;

interface CloseIconProps {
    customImage?: boolean;
    relative?: boolean;
}

const CloseIcon = styled.div<CloseIconProps>`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  margin-left: 1rem;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.1s;

  &:hover {
    opacity: 1;
  }

  img {
    width: 2rem;
  }

  ${({theme, customImage}) =>
          !customImage &&
          `
        &::before,
        &::after {
            position: absolute;
            left: 10px;
            content: ' ';
            height: 20px;
            width: 2px;
            border-radius: 3px;
            background-color: ${theme.colors.black};
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    `}

  ${({relative}) =>
          !relative &&
          `
        position: absolute;
        right: 3rem;
        top: 3rem;
    `}
`;

const ModalWrapperC = styled.div`
  background-color: ${({theme}) => theme.colors.lightGrey}30;
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  overflow: scroll;
`

interface ModalProps {
    wider?: boolean;
}

const Modal = styled.div<ModalProps>`
  margin: 0 auto;
  max-width: 75rem;
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.white}10;
  border-radius: ${({theme}) => theme.borderRadius1};
  box-shadow: ${({theme}) => theme.boxShadow1};
  
  ${({wider}) =>
          wider &&
          `
        max-width: 70rem;
    `}
  > span {
    display: block;
    margin: 2.5rem 0;
    border-bottom: ${({theme}) => theme.border};
  }

  @media (max-width: 800px) {
    > span {
      margin: 2rem 0;
    }
  }
`;


const ExplanationModal = styled(Modal)`
  width: 100%;
  max-width: 55rem;
  padding: 2.5rem 2rem;

  button {
    justify-self: flex-end;
  }

  @media (max-width: 600px) {
    max-width: 90vw;
    padding: 2rem 0;

    > div,
    form {
      margin: 0 2rem;
    }
  }
`;

const ExplanationModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${({theme}) => theme.colors.lightGrey};
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
`


export {
    ExplanationWrapper,
    NewBadgeWrapper,
    CloseIcon,
    ExplanationModal,
    ModalWrapperC,
    ExplanationModalFooter
};
