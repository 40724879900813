import {Button, Container, ContentWrapper, Divider, Heading1} from "../styled";
import HelloSrc from "../../assets/img/hello.svg"
import finishSurvey from "../../api/finishSurvey";

const ReturnComponent = () => {

    return (
        <Container>
            <ContentWrapper>
                <Divider height={15}/>

                <Heading1>
                    Return your survey
                </Heading1>
                <Divider height={1}/>

                <p>
                    Thank you for your interest in this study. However, it is critical that you
                    consent to the privacy statement to complete this task.
                    As a result, I'm afraid you won't be able to
                    continue with the experiment. Please return your submission on Prolific by
                    clicking the 'stop without completing' button.'
                </p>
                <Divider height={1}/>

                <Divider/>
                <div>
                    <Button primary={true} onClick={() => window.location.href = "https://app.prolific.co/submissions/complete?cc=null"}>
                        STOP WITHOUT COMPLETING
                    </Button>
                </div>
            </ContentWrapper>
        </Container>
    )

}


export default ReturnComponent
