import {Survey} from "../components/types/types";
import {apiUrl} from "./config";

const fetchSurvey = async (survey_id: string): Promise<Survey> => {
    try {
        const res = await fetch(apiUrl + '/survey/' + survey_id, {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        if (!res.ok) throw new Error();
        else {
            // TODO: Check localhost
            localStorage.setItem("survey_token", "survey_token_from_api")
            return await res.json();
        }
    } catch (e) {
        throw new Error();
    }
};

export default fetchSurvey;
