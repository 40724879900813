import styled from "styled-components";

const ConsentWrapper = styled.div`
  max-width: 770px;
  margin: 100px auto 0 auto;
  padding: 3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.lightGrey}20;
`

const ConsentHeader = styled.div`
  padding: 1rem 0;

  > p {
    font-size: small;
    color: ${({theme}) => theme.colors.brandTwo};
  }
`

const ConsentBody = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  > div {
    padding: 0.5rem 0;
  }
`

const PublishersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  
  > div {
    min-width: 200px;
  }
`

const ConsentAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
`


export {
    ConsentWrapper,
    ConsentBody,
    ConsentHeader,
    ConsentAction,
    PublishersContainer
}
