import {Answers, Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled/layout";
import {
    Button, ColoredSpan,
    ExplanationModal, ExplanationModalFooter,
    Heading3,
    Heading4,
} from "../../styled";
import {useEffect, useState} from "react";
import ModalWrapper from "../../modals/ModalWrapper";
import {
    AttributeContainer,
    ChoiceOption,
    ChoiceOptionWrapper,
    ChoiceWrapper,
    ConjointWrapper, Explanation, ExplanationHeader, ExplanationWrapper, WaitingScreen
} from "../stage_conjoint/conjointStageStyles";
import ExplanationSrc from "../../../assets/img/switching-explanation.png";
import {explanationWaitingTime, selectionWaitingTime} from "../config";

interface SwitchingComponentProps {
    survey: Survey,
    setSurveyStage: (stage: SurveyStage) => void
    addAnswers: (answers: Answers) => void
}

const SwitchingComponent: React.FC<SwitchingComponentProps> = ({
                                                                   survey,
                                                                   setSurveyStage,
                                                                   addAnswers
                                                               }) => {

    const [showExplanation, setShowExplanation] = useState<boolean>(true)

    const numChoiceSets = survey.config.switching_sets.length
    const [choiceIdx, setChoiceIdx] = useState<number>(0)
    const [choices, setChoices] = useState<[number, number, number][]>([])

    function getAttributeData(idx: number) {
        return survey.config.attributes[idx]
    }

    function makeChoice(newChoice: number) {
        if (choiceIdx < numChoiceSets && choices.length < numChoiceSets) {
            let tmp = choices
            tmp.push([choiceIdx, newChoice, Date.now()])
            setChoices(tmp)
        }
        if (choiceIdx < numChoiceSets-1) {
            setChoiceIdx(choiceIdx + 1)
        } else {
            finishSwitching()
        }
    }

    const [waitingScreenActive, setWaitingScreenActive] = useState<boolean>(false)
    const [seconds, setSeconds] = useState(explanationWaitingTime);
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        let interval: any = null;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        } else if (seconds === 0) {
            setWaitingScreenActive(true)
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    function finishSwitching() {
        let tmpAnswers = survey.answers
        tmpAnswers.switching_decisions = choices
        addAnswers(tmpAnswers)
        setSurveyStage(SurveyStage.DEMOGRAPHICS)
    }

    return (
        <>
            {
                showExplanation &&
                <ModalWrapper onClose={() => setShowExplanation(false)}>
                    {/*<CloseIcon onClick={() => setShowExplanation(false)}/>*/}
                    <ExplanationModal>
                        <div>
                            <Heading3>Task: Stay or switch</Heading3>
                            <p><i>Explanation of task number two</i></p>
                            <Divider height={1}/>

                            <img src={ExplanationSrc} alt="Explanation"/>

                            <p>
                                In the upcoming task, we will show you one configuration of a
                                service on the left side. Assume you are currently using this
                                service.
                            </p>
                            <p>
                                On the right side, you will see an alternative service. You can
                                decide whether to
                                stay with the current service or to switch to the new one.
                            </p>
                            <p>
                                Please note that switching a service may come at a tradeoff,
                                e.g., additional onboarding time or a different level of transaction
                                privacy.
                            </p>
                        </div>
                        <Divider height={3}/>
                        <ExplanationModalFooter>
                            <div>
                                <Button secondary={true} onClick={() => {
                                    window.scrollTo(0, 0);
                                    setShowExplanation(false)
                                    setSeconds(selectionWaitingTime)
                                    setWaitingScreenActive(false)
                                }}>
                                    <span>OK, continue</span>
                                </Button>
                            </div>
                        </ExplanationModalFooter>
                    </ExplanationModal>
                </ModalWrapper>
            }

            <>
                {
                    survey &&
                    <>
                        <ConjointWrapper>
                            <Heading3>
                                Would you switch or stay?
                            </Heading3>
                            <p>
                                If you feel the current service is more attractive to you than the
                                new one on the right, click stay. Otherwise switch to the new one.
                            </p>
                            <Divider height={2}/>
                            <p>Choice set <b>{choiceIdx + 1}</b> of {numChoiceSets}</p>
                            <ChoiceWrapper>
                                { waitingScreenActive &&
                                    <>
                                        <ChoiceOptionWrapper>
                                            <ChoiceOption borderWidth={3}>
                                                <Heading4>
                                                    Current option
                                                </Heading4>
                                                {
                                                    survey.config.switching_sets[choiceIdx][0].map((val, idx) => {
                                                        return (
                                                            <>
                                                                {(idx !== 3) &&
                                                                    <div key={idx}>
                                                                        <div>
                                                                            {getAttributeData(idx).name}
                                                                        </div>
                                                                        <div>
                                                                            {getAttributeData(idx).values[val].value}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(idx === 3) &&
                                                                    <div key={idx}>
                                                                        <div style={{textDecoration: 'line-through'}}>
                                                                            {getAttributeData(idx).name}
                                                                        </div>
                                                                        <div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ChoiceOption>
                                            <Button disabled={!waitingScreenActive} secondary={true}
                                                    onClick={() => {
                                                        setSeconds(selectionWaitingTime)
                                                        setWaitingScreenActive(false)
                                                        makeChoice(0)
                                                    }}>
                                                {
                                                    !waitingScreenActive &&
                                                    <span>Consider the options ({seconds}s)</span>
                                                }
                                                {
                                                    waitingScreenActive &&
                                                    <span>Stay with current provider</span>
                                                }
                                            </Button>
                                        </ChoiceOptionWrapper>
                                        <ChoiceOptionWrapper>
                                            <ChoiceOption>
                                                <Heading4>
                                                    Alternative {choiceIdx + 1}
                                                </Heading4>
                                                {
                                                    survey.config.switching_sets[choiceIdx][1].map((val, idx) => {
                                                        return (
                                                            <div key={idx}>
                                                                <div>
                                                                    {getAttributeData(idx).name}
                                                                </div>
                                                                <div>
                                                                    {getAttributeData(idx).values[val].value}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ChoiceOption>
                                            <Button disabled={!waitingScreenActive} secondary={true}
                                                    onClick={() => {
                                                        setSeconds(selectionWaitingTime)
                                                        setWaitingScreenActive(false)
                                                        makeChoice(1)
                                                    }}>
                                                {
                                                    !waitingScreenActive &&
                                                    <span>Consider the options ({seconds}s)</span>
                                                }
                                                {
                                                    waitingScreenActive &&
                                                    <span>Switch to new provider</span>
                                                }
                                            </Button>
                                        </ChoiceOptionWrapper>
                                    </>
                                }
                                { !waitingScreenActive &&
                                    <WaitingScreen>
                                        Loading new choice set...
                                    </WaitingScreen>
                                }
                            </ChoiceWrapper>
                        </ConjointWrapper>

                        <ExplanationWrapper>
                            <>
                                <Heading3>
                                    Attribute descriptions
                                </Heading3>
                                <p>
                                    Below, you find a description of the attributes that describe
                                    each
                                    service that occurs in the survey. Please take your time to read
                                    them carefully.
                                </p>
                                {
                                    survey && survey.config.attributes.map((attribute, idx) => {
                                        return (
                                            <Explanation grey={true} key={idx}>
                                                <ExplanationHeader>
                                                    <div>
                                                        <p>
                                                            <ColoredSpan>
                                                                <b>{attribute.name}</b>
                                                            </ColoredSpan>
                                                            <br/>
                                                            <span>{attribute.description}</span>
                                                        </p>
                                                    </div>
                                                </ExplanationHeader>
                                                <Divider height={0.1}/>
                                                {
                                                    attribute.values.map((v, idx) => {
                                                        return (
                                                            <AttributeContainer key={idx}>
                                                                <div>
                                                                    <b>{v.value}:</b>
                                                                </div>
                                                                <div>
                                                                    {v.explanation}
                                                                </div>
                                                            </AttributeContainer>
                                                        )
                                                    })
                                                }
                                            </Explanation>
                                        )
                                    })
                                }
                            </>
                        </ExplanationWrapper>
                    </>
                }
            </>
        </>
    )

}


export default SwitchingComponent
