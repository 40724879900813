import {ConsentAction, ConsentBody, ConsentHeader, ConsentWrapper, PublishersContainer} from "./consentStageStyles";
import {Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled/layout";
import {Button, Heading2, Heading3, Heading4} from "../../styled";
import { Link } from "react-router-dom";

interface DetailComponentProps {
    survey: Survey | null,
    setSurveyStage: (stage: SurveyStage) => void
}

const DetailComponent: React.FC<DetailComponentProps> = ({survey, setSurveyStage}) => {

    return (
        <>
            {
                survey &&
                <ConsentWrapper>
                    <ConsentHeader>
                        <Heading2>
                            Welcome to our survey on <br/>
                            <b>Preferences in interoperable systems</b>
                        </Heading2>
                    </ConsentHeader>

                    <Divider/>

                    <ConsentBody>
                        <p>
                            Thank you for taking part in our survey on “Preferences in interoperable systems”.
                            Before you start the survey, please carefully read the information
                            below. If you have any questions or concerns feel free to contact us.
                        </p>

                        <Divider/>

                        <Heading3>
                            Contact
                        </Heading3>
                        <p>
                            Technical University of Munich<br/>
                            Professorship for Cyber Trust<br/>
                        </p>
                        <PublishersContainer>
                            <div>
                                <p>
                                    Johannes Pecher, M.Sc.<br/>
                                    <code>johannes [d0t] pecher [a7] tum.de</code>
                                </p>
                            </div>
                            <div>
                                <p>
                                    Emmanuel Syrmoudis, M.Sc.<br/>
                                    <code>emmanuel [d0t] syrmoudis [a7] tum.de</code>
                                </p>
                            </div>
                            <div>
                                <p>
                                    Prof. Dr. Jens Grossklags<br/>
                                    <code>jens [d0t] grossklags [a7] tum.de</code>
                                </p>
                            </div>
                        </PublishersContainer>

                        <Divider/>

                        <div>
                            <Heading3>
                                Consent Form
                            </Heading3>
                        </div>

                        <div>
                            <p>
                                Hereby, I acknowledge that I voluntarily participate in this study.
                                I was informed about the following:
                            </p>

                            <Heading4>
                                Procedure
                            </Heading4>
                            <p>
                                In this survey, you will be asked questions mainly addressing your
                                registration preferences for online services.
                                It consists of two parts. The second part will build on the first
                                part and the choices you made.
                                The average time needed to complete the survey is 10 minutes.
                            </p>

                            <Heading4>
                                Anonymity
                            </Heading4>
                            <p>
                                Collected data is anonymously stored and analyzed. No personal
                                information (e.g. e-mail) is captured.
                            </p>

                            <Heading4>
                                Reward
                            </Heading4>
                            <p>
                                The participation is remunerated according to the conditions indicated
                                on Prolific.
                            </p>

                            <Heading4>
                                Data Usage
                            </Heading4>
                            <p>
                                Due to academic transparency, anonymized data is available to third
                                parties for re-use after completion of the study. Purpose, type, and extent of this re-use cannot be
                                foreseen.
                            </p>

                            <Divider/>

                            <Heading4>
                                Consent
                            </Heading4>
                            <p>
                                By checking the box below, I acknowledge that I was informed about the
                                procedure of the study and that I agree to the text above.
                            </p>
                        </div>
                    </ConsentBody>
                    <ConsentAction>
                        <Link to={"/survey/return/prolific"}>
                            <Button dark={true}>
                                <div>
                                    <Divider height={0.5}/>
                                    No, I do NOT agree
                                    <Divider height={1}/>
                                    <span style={{fontWeight:"lighter"}}>(do not participate in this study)</span>
                                </div>
                            </Button>
                        </Link>
                        <Button primary={true} onClick={() => setSurveyStage(SurveyStage.SETTING)}>
                            <div>
                                <Divider height={0.5}/>
                                Yes, I agree
                                <Divider height={1}/>
                                <span style={{fontWeight:"lighter"}}>(participate in this study)</span>
                            </div>
                        </Button>
                    </ConsentAction>
                </ConsentWrapper>
            }
        </>
    )

}


export default DetailComponent
