
import {Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled/layout";
import {Button, Heading2, Heading3, Heading4} from "../../styled";
import { FinishedWrapper, ImgWrapper } from "./finishedStageStyles";

import LoveResearchSrc from "../../../assets/img/love-research.png"
import {useEffect, useState} from "react";
import fetchRedirectUrl from "../../../api/getRedirectUrl";
import finishSurvey from "../../../api/finishSurvey";

interface DetailComponentProps {
    survey: Survey | null,
    setSurveyStage: (stage: SurveyStage) => void
    redirect: string
}

const FinishedComponent: React.FC<DetailComponentProps> = ({survey, setSurveyStage, redirect}) => {

    return ( survey &&
        <FinishedWrapper>
            <div>
                <ImgWrapper>
                    <img src={LoveResearchSrc} alt={"Love Research"}/>
                </ImgWrapper>

                <Divider height={3}/>

                <Heading2>
                    Thank you!
                </Heading2>
                <Divider/>
                <p>
                    Thank you for taking part in our survey and making a contribution to academic research.
                </p>
                <p>
                    To finish your research, please click the button below. We will redirect you back
                    to Prolific so you can collect your reward.
                </p>
                <p>
                    Cheers 👌
                </p>
            </div>
            <Divider/>
            <div>
                <Button primary={true} onClick={() => finishSurvey(survey.survey_id)}>
                    FINISH SURVEY
                </Button>
            </div>
        </FinishedWrapper>
    )

}


export default FinishedComponent
