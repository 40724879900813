import {Answers, Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled/layout";
import {Button, Heading2, Heading3, Heading4} from "../../styled";
import { DemographicsWrapper } from "./demographicsStyles";
import Questionnaire from "../questionnaire/Questionnaire";

interface DemographicsComponentProps {
    survey: Survey | null,
    setSurveyStage: (stage: SurveyStage) => void,
    addAnswers: (answers: Answers) => void
}

const DemographicsComponent: React.FC<DemographicsComponentProps> = ({survey, setSurveyStage, addAnswers}) => {

    return (
        <>
            <DemographicsWrapper>
                <Heading2>
                    Demographics
                </Heading2>
                <Divider/>

                {
                    survey && <Questionnaire survey={survey} setSurveyStage={setSurveyStage} addAnswers={addAnswers}/>
                }

            </DemographicsWrapper>
        </>
    )

}


export default DemographicsComponent
