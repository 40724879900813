import React, { useEffect, useRef } from 'react';
import { ModalWrapperC } from '../styled';

interface Props {
    onClose: any,
    children: React.ReactNode;
}

const ModalWrapper: React.FC<Props> = ({ onClose, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clickListener = (e: any) => {
            if (ref.current && !ref.current.contains(e.target)) onClose();
        };

        const escapeListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') onClose();
        };

        document.addEventListener('click', clickListener, true);
        document.addEventListener('keyup', escapeListener, true);

        return () => {
            document.removeEventListener('click', clickListener, true);
            document.removeEventListener('keyup', escapeListener, true);
        };
    }, [ref, onClose]);

    return <ModalWrapperC ref={ref}>{children}</ModalWrapperC>;
};

export default ModalWrapper;
