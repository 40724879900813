import styled from "styled-components";


const DemographicsWrapper = styled.div`
  max-width: 770px;
  margin: 100px auto 0 auto;
  padding: 3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.lightGrey}20;
`

export {
    DemographicsWrapper
}
