import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    fontFamily:
        '"Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamily2:
        '"Rubik Bold", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontFamily3:
        '"Rubik Light", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius1: '8px',
    borderRadius2: '16px',

    boxShadow1: '0 0 10px rgba(80, 80, 80, 0.1)',

    colors: {
        brandOne: '#0045FF',
        brandOneAccent1: '#386eff',
        brandOneAccent2: '#45e4ff',
        brandOneAccent3: '#ffffff',
        brandOneAccent4: '#ffffff',

        brandTwo: '#5ba845',
        brandTwoAccent1: '#45c037',
        brandTwoAccent2: '#29d73f',
        brandTwoAccent3: '#b6a6ea',
        brandTwoAccent4: '#d3d3d3',

        brandThree: '#FFCE00',
        brandThreeAccent1: '#ffe278',
        brandThreeAccent2: '#fff6d6',
        brandThreeAccent3: '#ffffff',
        brandThreeAccent4: '#ffffff',

        red: '#FF4848',
        green: '#45b258',
        orange: '#e88828',
        black: '#181818',
        darkGrey: '#282a2f',
        lightGrey: '#969697',
        offWhite: '#e0e0e0',
        white: '#ebeef2'
    }
};

export default theme;
