import {DateInput, Input, TextArea} from "../../styled/inputs";
import {
    BoolQuestionInputContainer,
    CountryContainer,
    CountryWrapper,
    DateSelectInputContainer,
    LikertOption,
    LikertOptionContainer,
    OptionContainer,
    QuestionBody,
    QuestionHeader,
    QuestionWrapper,
    RadioInput,
    SuggestionsContainer
} from "./questionStyles";
import React, {useState} from "react";

import DeleteIcon from '../../../assets/img/delete.svg'
import PlusIcon from '../../../assets/img/plus.svg'
import {
    BoolQuestion,
    CountrySelect,
    DateRange,
    DateSelect, LikertScale, MultipleChoice, SingleChoice,
    TextQuestion
} from './types'

interface QuestionComponentProps<QuestionType> {
    question: QuestionType;
    updateFunction: (key: string, q: QuestionType) => void;
}

const TextQuestionComponent: React.FC<QuestionComponentProps<TextQuestion>> = ({
                                                                                   question,
                                                                                   updateFunction
                                                                               }) => {
    const [textAnswer, setTextAnswer] = useState<string>(question.text_answer)

    const handleChange = (event: any) => {
        setTextAnswer(event.target.value);
        question.text_answer = event.target.value;
        updateFunction(question.id, question);
    }

    return (
        <QuestionWrapper>
            <QuestionHeader>
                {question.text}
                <p>
                    {question.annotation}
                </p>
            </QuestionHeader>
            <QuestionBody>
                <TextArea
                    placeholder={question.placeholder || "Type your answer"}
                    value={textAnswer}
                    onChange={handleChange}
                />
            </QuestionBody>
        </QuestionWrapper>
    )
}

const MultipleChoiceQuestionComponent: React.FC<QuestionComponentProps<MultipleChoice>> =
    ({question, updateFunction}) => {

        const [selections, setSelections] = useState<number[]>(() => {
            return new Array(question.options.length).fill(0)
        })
        const [updates, setUpdates] = useState<number>(0)

        function select(idx: number) {
            console.log("selecting", idx)
            let tmp = selections
            tmp[idx] = 1
            setSelections(tmp)
            setUpdates(updates + 1)
        }

        function unselect(idx: number) {
            console.log("unselecting", idx)
            let tmp = selections
            tmp[idx] = 0
            setSelections(tmp)
            setUpdates(updates - 1)
        }

        const handleUpdate = async (idx: number) => {
            console.log("handling update for mc quest ion")
            if (selections[idx] === 0) {
                select(idx)
            } else {
                unselect(idx)
            }

            question.selected_indices = selections

            question.selected_answers = []
            for (let i in selections) {
                if (selections[i] === 1) {
                    question.selected_answers.push(question.options[i])
                }
            }
            updateFunction(question.id, question)
        }

        return (
            <QuestionWrapper>
                <QuestionHeader>
                    {question.text}<br/>
                    <span style={{fontWeight: "lighter"}}><small>Multiple Choice: Select one or many options</small></span>
                </QuestionHeader>
                <QuestionBody>
                    <OptionContainer>
                        {
                            question.options.map((option, idx) => {
                                return (
                                    <RadioInput onClick={() => handleUpdate(idx)}
                                                selected={selections[idx] === 1} key={idx}>
                                        <div
                                            id={String(idx)}
                                        />
                                        <div>
                                            <div>
                                                {idx + 1}.)
                                            </div>
                                            <div>
                                                {option}
                                            </div>
                                        </div>
                                    </RadioInput>
                                )
                            })
                        }
                    </OptionContainer>
                </QuestionBody>
            </QuestionWrapper>
        )
    }

const SingleChoiceQuestionComponent: React.FC<QuestionComponentProps<SingleChoice>> =
    ({question, updateFunction}) => {
        const [selected, setSelected] = useState<number>()

        function selectOption(idx: number) {
            setSelected(idx)
            return idx
        }

        const handleUpdate = async (idx: number) => {
            question.selected_index = selectOption(idx)
            question.selected_answer = question.options[question.selected_index]
            updateFunction(question.id, question)
        }

        return (
            <QuestionWrapper>
                <QuestionHeader>
                    {question.text}<br/>
                    <span style={{fontWeight: "lighter"}}><small>Single Choice: Select only one option.</small></span>
                </QuestionHeader>
                <QuestionBody>
                    <OptionContainer>
                        {
                            question.options.map((option, idx) => {
                                return (
                                    <RadioInput onClick={() => handleUpdate(idx)}
                                                selected={idx === selected} key={idx}>
                                        <div
                                            id={String(idx)}
                                        />
                                        <div>
                                            <div>
                                                {idx + 1}.)
                                            </div>
                                            <div>
                                                {option}
                                            </div>
                                        </div>
                                    </RadioInput>
                                )
                            })
                        }
                    </OptionContainer>
                </QuestionBody>
            </QuestionWrapper>
        )
    }

const LikertScaleComponent: React.FC<QuestionComponentProps<LikertScale>> =
    ({question, updateFunction}) => {

        const [selected, setSelected] = useState<number>()

        function selectOption(idx: number) {
            setSelected(idx)
            return idx
        }

        const handleUpdate = async (idx: number) => {
            question.selected_index = selectOption(idx)
            updateFunction(question.id, question)
        }

        return (
            <QuestionWrapper>
                <QuestionHeader>
                    {question.text}
                </QuestionHeader>
                <QuestionBody>
                    <LikertOptionContainer>
                        <LikertOption onClick={() => handleUpdate(0)} selected={0 === selected}>
                            <div>
                                <div>1</div>
                            </div>
                        </LikertOption>
                        <LikertOption onClick={() => handleUpdate(1)} selected={1 === selected}>
                            <div>
                                <div>2</div>
                            </div>
                        </LikertOption>
                        <LikertOption onClick={() => handleUpdate(2)} selected={2 === selected}>
                            <div>
                                <div>3</div>
                            </div>
                        </LikertOption>
                        <LikertOption onClick={() => handleUpdate(3)} selected={3 === selected}>
                            <div>
                                <div>4</div>
                            </div>
                        </LikertOption>
                        <LikertOption onClick={() => handleUpdate(4)} selected={4 === selected}>
                            <div>
                                <div>5</div>
                            </div>
                        </LikertOption>
                    </LikertOptionContainer>
                    <LikertOptionContainer>
                        <div>
                            Strongly disagree
                        </div>
                        <div>
                            Neither agree nor disagree
                        </div>
                        <div>
                            Strongly agree
                        </div>
                    </LikertOptionContainer>
                </QuestionBody>
            </QuestionWrapper>
        )
    }

const BoolQuestionComponent: React.FC<QuestionComponentProps<BoolQuestion>> = (
    {
        question,
        updateFunction
    }
) => {
    const [boolState, setBoolState] = useState<boolean>(question.bool_answer)

    const toggleBoolState = () => {
        let newState = !boolState
        setBoolState(newState)
        return newState
    }

    const handleUpdate = async () => {
        question.bool_answer = toggleBoolState();
        updateFunction(question.id, question)
    }

    return (
        <QuestionWrapper>
            <QuestionHeader>
                {question.text}
                <p>
                    {question.annotation}
                </p>
            </QuestionHeader>
            <QuestionBody>
                <BoolQuestionInputContainer>
                    <RadioInput onClick={handleUpdate} selected={boolState}>
                        <input type={"radio"} id={"yes"} checked={boolState}/>
                        <label>Yes</label>
                    </RadioInput>
                    <RadioInput onClick={handleUpdate} selected={!boolState}>
                        <input type={"radio"} id={"no"} checked={!boolState}/>
                        <label>No</label>
                    </RadioInput>
                </BoolQuestionInputContainer>
            </QuestionBody>
        </QuestionWrapper>
    )
}

const DateSelectComponent: React.FC<QuestionComponentProps<DateSelect>> = ({
                                                                               question,
                                                                               updateFunction
                                                                           }) => {
    const [date, setDate] = useState<Date>(question.date)

    const handleChange = (event: any) => {
        setDate(event.target.value);
        question.date = event.target.value;
        updateFunction(question.id, question);
    }

    return (
        <QuestionWrapper>
            <QuestionHeader>
                {question.text}
            </QuestionHeader>
            <QuestionBody>
                <DateSelectInputContainer>
                    <DateInput type={"date"}/>
                </DateSelectInputContainer>
            </QuestionBody>
        </QuestionWrapper>
    )
}

const DateRangeComponent: React.FC<QuestionComponentProps<DateRange>> = ({
                                                                             question,
                                                                             updateFunction
                                                                         }) => {
    const [startDate, setStartDate] = useState<Date>(question.start_date)
    const [endDate, setEndDate] = useState<Date>(question.end_date)

    const handleChangeStartDate = (event: any) => {
        setStartDate(event.target.value);
        question.start_date = event.target.value;
        updateFunction(question.id, question);
    }

    const handleChangeEndDate = (event: any) => {
        setEndDate(event.target.value);
        question.end_date = event.target.value;
        updateFunction(question.id, question);
    }

    return (
        <QuestionWrapper>
            <QuestionHeader>
                {question.text}
            </QuestionHeader>
            <QuestionBody>
                <DateSelectInputContainer>
                    <DateInput type={"date"} onChange={e => handleChangeStartDate(e)}/>
                    <p>to</p>
                    <DateInput type={"date"} onChange={e => handleChangeEndDate(e)}/>
                </DateSelectInputContainer>
            </QuestionBody>
        </QuestionWrapper>
    )
}

const CountrySelectComponent: React.FC<QuestionComponentProps<CountrySelect>> = ({
                                                                                     question,
                                                                                     updateFunction
                                                                                 }) => {
    interface Country {
        name: string;
        iso: string;
    }

    const countryDb: Country[] = [
        {"name": "germany", "iso": "DE"},
        {"name": "spain", "iso": "ES"},
        {"name": "mexico", "iso": "MX"},
        {"name": "france", "iso": "FR"},
    ]
    const getCountrySuggestions = (param: string) => {
        if (param.length >= 3) {
            let s = countryDb.filter((item) => {
                return item.name.includes(param.toLowerCase())
            })
            if (s.length > 0) {
                setSuggestions(s)
            }
        } else {
            setSuggestions([])
        }
    }

    const [suggestions, setSuggestions] = useState<Country[]>([])
    const [selectedCountries, setSelectedCountries] = useState<Country[]>([])
    const [currentSearch, setCurrentSearch] = useState<string>("")

    const addCountry = (country: Country) => {
        let tmp = selectedCountries
        if (!containsCountry(country, tmp)) {
            tmp.push(country)
        }
        setSelectedCountries(tmp)
    }
    const removeCountry = (c: Country) => {
        let tmp = selectedCountries
        tmp = tmp.filter((item) => {
            return !(c.iso === item.iso)
        })
        setSelectedCountries(tmp)
    }

    const containsCountry = (obj: Country, list: Country[]) => {
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].iso === obj.iso) {
                return true;
            }
        }
        return false;
    }

    return (
        <QuestionWrapper>
            <QuestionHeader>
                {question.text}
                <p>
                    Type a country name and click it to select.
                </p>
            </QuestionHeader>
            <QuestionBody>
                {selectedCountries.length > 0 &&
                    <SuggestionsContainer>
                        {
                            selectedCountries.map((c) => {
                                return (
                                    <CountryWrapper
                                        key={c.iso}
                                        onClick={() => removeCountry(c)}
                                    >
                                        {c.name}
                                        <img src={DeleteIcon} alt={"delete"}/>
                                    </CountryWrapper>
                                )
                            })
                        }
                    </SuggestionsContainer>
                }
                <div>
                    <Input
                        value={currentSearch}
                        type="text"
                        placeholder="Enter a country"
                        onChange={(e) => {
                            setCurrentSearch(e.target.value)
                            getCountrySuggestions(e.target.value)
                        }}
                    />
                </div>
                {suggestions.length > 0 &&
                    <CountryContainer>
                        {
                            suggestions.map((c) => {
                                return (
                                    <CountryWrapper
                                        light={true}
                                        key={c.iso}
                                        onClick={(e) => {
                                            addCountry(c)
                                            setCurrentSearch("")
                                            setSuggestions([])
                                        }}
                                    >
                                        {c.name}
                                        <img src={PlusIcon} alt={"add"}/>
                                    </CountryWrapper>
                                )
                            })
                        }
                    </CountryContainer>
                }
            </QuestionBody>
        </QuestionWrapper>
    )
}

export {
    TextQuestionComponent,
    MultipleChoiceQuestionComponent,
    SingleChoiceQuestionComponent,
    LikertScaleComponent,
    BoolQuestionComponent,
    DateSelectComponent,
    DateRangeComponent,
    CountrySelectComponent
}
