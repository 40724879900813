import {
    FinishModal,
    Questionnaire,
    QuestionnaireBody,
    QuestionnaireFooter,
    QuestionnaireHeader,
    SectionBody,
    SectionHead
} from "./questionnaireStyles"
import {
    BoolQuestionComponent,
    DateRangeComponent,
    DateSelectComponent,
    LikertScaleComponent,
    MultipleChoiceQuestionComponent,
    SingleChoiceQuestionComponent,
    TextQuestionComponent
} from "./questions";
import {testSections} from "./sections";
import {Button} from "../../styled/inputs";
import {useEffect, useState} from "react";
import {
    BoolQuestion,
    CountrySelect,
    DateRange,
    DateSelect,
    Question,
    Section,
    TextQuestion,
    SingleChoice, MultipleChoice, LikertScale
} from "./types";
import {Answers, Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled";

interface QuestionnaireProps {
    survey: Survey,
    setSurveyStage: (stage: SurveyStage) => void
    addAnswers: (answers: Answers) => void
}

const QuestionnaireComponent: React.FC<QuestionnaireProps> = ({survey, setSurveyStage, addAnswers}) => {
    const [sections, setSections] = useState<Section[]>(testSections)
    const [questionnaireState, setQuestionnaireState] = useState<Map<string, Question>>(new Map())
    const [currentSection, setCurrentSection] = useState<Section>(sections[0])

    // Load all questions into the questionnaire state (run once)
    useEffect(() => {
        setSections(sections)
        let tmp: Map<string, Question> = new Map<string, Question>()
        for (let section of sections) {
            for (let question of section.questions) {
                tmp.set(question.id, resolveQuestionType(question))
            }
        }
        setQuestionnaireState(tmp)
    }, [sections])

    // Updates the state (the answers) for a given question
    function updateState(
        questionId: string,
        q: TextQuestion | BoolQuestion | DateSelect | DateRange | CountrySelect | SingleChoice | MultipleChoice | LikertScale
    ) {
        q.answered_at = Date.now()
        let tmp = questionnaireState.set(questionId, q)
        setQuestionnaireState(tmp)
    }

    // Resolves a question's type based on its type attribute
    function resolveQuestionType<QuestionType>(q: any): QuestionType {
        let res: Question | null = null
        switch (q.type) {
            case "TEXT":
                res = q as TextQuestion;
                break;
            case "BOOL":
                res = q as BoolQuestion;
                break;
            case "COUNTRY_SELECT":
                res = q as CountrySelect;
                break;
            case "DATE":
                res = q as DateSelect;
                break;
            case "DATE_RANGE":
                res = q as DateRange;
                break;
            case "SINGLE_CHOICE":
                res = q as SingleChoice;
                break;
            case "MULTIPLE_CHOICE":
                res = q as MultipleChoice;
                break;
            case "LIKERT_SCALE":
                res = q as LikertScale;
                break;
            default:
                TypeError("unknown question type")
        }
        if (res) {
            return res as unknown as QuestionType
        }
        throw TypeError("wrong type used for question")
    }

    // Switch to next section
    const nextSection = () => {
        if (currentSection.index < sections.length - 1) {
            syncAnswers()
            if (isComplete(currentSection)) {
                window.scrollTo(0, 0);
                setCurrentSection(sections[currentSection.index + 1])
            } else {
                alert("This section is incomplete. Please answer all questions before you continue.")
            }
        }
    }

    // Go back to previous section
    const previousSection = () => {
        if (currentSection.index > 0) {
            window.scrollTo(0, 0);
            setCurrentSection(sections[currentSection.index - 1])
        }
    }

    function isComplete(section: Section) {
        for (let question of section.questions) {
            if (!isAnswered(question)) {
                return false
            }
        }
        return true
    }

    function isAnswered(question: Question) {
        switch (question.type) {
            case "SINGLE_CHOICE":
                return ((question.selected_index !== undefined) && (question.answered_at !== undefined));
            case "LIKERT_SCALE":
                return ((question.selected_index !== undefined) && (question.answered_at !== undefined));
            case "MULTIPLE_CHOICE":
                return (() => {
                    if(question.answered_at !== undefined) {
                        question.selected_indices!.forEach((x) => {
                            if (x === 1) {
                                return true;
                            }
                        })
                    }
                    return false
                })
            case "TEXT":
                // return ((question.text_answer !== undefined) && (question.text_answer.length > 0))
                return true
            default:
                return false
        }
    }

    const [showFinishModalState, setShowFinishModalState] = useState<boolean>(false)
    const finishQuestionnaire = () => {
        // TODO: validate answers

        // set finished and move on to the next state
        if (isComplete(currentSection)) {
            syncAnswers()
            setSurveyStage(SurveyStage.FINISHED)
        } else {
            alert("Please answer all questions before you continue.")
        }
    }

    function syncAnswers() {
        let tmpAnswers = survey.answers
        tmpAnswers.demographic_questions = Array.from(questionnaireState).map((x) => x[1])
        addAnswers(tmpAnswers)
    }

    return (
        <Questionnaire>
            { showFinishModalState &&
                <FinishModal>
                    <div>
                        <div>
                            <h3>
                                Finish survey?
                            </h3>
                            <br/>
                            <p>
                                As soon as you click "finish", the survey will be closed and you
                                will be redirected to Prolific to collect your reward.
                            </p>
                            <p>
                                Thank you for taking part in the survey!
                            </p>
                        </div>
                        <div>
                            <Button
                                onClick={() => setShowFinishModalState(false)}
                            >
                                No, go back!
                            </Button>
                            <Button
                                onClick={() => finishQuestionnaire()}
                            >
                                FINISH
                            </Button>
                        </div>
                    </div>
                </FinishModal>
            }

            <QuestionnaireHeader>
                Section {currentSection.index + 1} of {sections.length}
            </QuestionnaireHeader>

            <QuestionnaireBody>

                <div key={currentSection.index}>
                    <SectionHead>
                        <h2>
                            {currentSection.name}
                        </h2>
                        <Divider height={0.5}/>
                        <p>
                            {currentSection.subtitle}
                        </p>
                    </SectionHead>
                    <Divider/>
                    <SectionBody>
                        <>
                            {
                                currentSection.questions.map((q) => {
                                    switch (q.type) {
                                        case "TEXT":
                                            return (
                                                <TextQuestionComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<TextQuestion>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "BOOL":
                                            return (
                                                <BoolQuestionComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<BoolQuestion>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "DATE":
                                            return (
                                                <DateSelectComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<DateSelect>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "DATE_RANGE":
                                            return (
                                                <DateRangeComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<DateRange>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "SINGLE_CHOICE":
                                            return (
                                                <SingleChoiceQuestionComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<SingleChoice>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "MULTIPLE_CHOICE":
                                            return (
                                                <MultipleChoiceQuestionComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<MultipleChoice>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                        case "LIKERT_SCALE":
                                            return (
                                                <LikertScaleComponent
                                                    key={q.id}
                                                    question={resolveQuestionType<LikertScale>(q)}
                                                    updateFunction={updateState}
                                                />
                                            )
                                    }
                                })
                            }
                        </>
                    </SectionBody>
                </div>
            </QuestionnaireBody>
            <QuestionnaireFooter>
                <div>
                    &nbsp;
                </div>
                <div>
                    {currentSection.index < sections.length - 1 &&
                        <Button
                            onClick={() => nextSection()}
                        >
                            Next
                        </Button>

                    }
                    {currentSection.index === sections.length - 1 &&
                        <Button
                            active={true}
                            onClick={() => setShowFinishModalState(true)}
                        >
                            Finish
                        </Button>

                    }
                </div>
            </QuestionnaireFooter>
        </Questionnaire>
    )
}


export default QuestionnaireComponent;
