import {Navigate, RouteProps} from "react-router";

interface Props extends RouteProps {
    children: any;
}

const Protected: React.FC<Props> = ({ children }) => {
    let isLoggedIn = false;
    if (localStorage.getItem('token')) {
        isLoggedIn = true
    }
    if (!isLoggedIn) {
        return <Navigate to="/error" replace />;
    }
    return children;
};
export default Protected;
