import {Answers} from "../components/types/types";
import {apiUrl} from "./config";

const apiUpdateAnswers = async (survey_id: string, answers: Answers): Promise<any> => {
    console.log("adding answers")
    try {
        const res = await fetch(apiUrl + '/survey/' + survey_id + '/answers', {
            method: 'post',
            body: JSON.stringify({
                "answers": answers
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });
        if (!res.ok) throw new Error();
    } catch (e) {
        console.log(e)
        throw new Error();
    }
};

export default apiUpdateAnswers;
