import {Answers, Survey, SurveyStage} from "../../types/types";
import {Divider} from "../../styled";
import {
    Button,
    ColoredSpan,
    ExplanationModal,
    ExplanationModalFooter,
    Heading3,
    Heading4
} from "../../styled";
import {
    AttributeContainer,
    ChoiceOption,
    ChoiceOptionWrapper,
    ChoiceWrapper,
    ConjointWrapper, Explanation, ExplanationHeader,
    ExplanationWrapper,
    WaitingScreen
} from "./conjointStageStyles";
import {useEffect, useState} from "react";
import ModalWrapper from "../../modals/ModalWrapper";

import ExplanationSrc from "../../../assets/img/conjoint-explanation.png"
import {explanationWaitingTime, selectionWaitingTime} from "../config";

interface ConjointComponentProps {
    survey: Survey,
    setSurveyStage: (stage: SurveyStage) => void
    addAnswers: (answers: Answers) => void
}

const ConjointComponent: React.FC<ConjointComponentProps> = ({
                                                                 survey,
                                                                 setSurveyStage,
                                                                 addAnswers
                                                             }) => {

    const [showExplanation, setShowExplanation] = useState<boolean>(true)

    const numChoiceSets = survey.config.choice_sets.length
    const [choiceIdx, setChoiceIdx] = useState<number>(0)
    const [choices, setChoices] = useState<[number, number, number][]>([])

    function getAttributeData(idx: number) {
        return survey.config.attributes[idx]
    }

    async function makeChoice(newChoice: number) {
        if (choiceIdx < numChoiceSets && choices.length < numChoiceSets) {
            let tmp = choices
            tmp.push([choiceIdx, newChoice, Date.now()])
            setChoices(tmp)
        }
        if (choiceIdx < numChoiceSets-1) {
            setChoiceIdx(choiceIdx + 1)
        } else {
            finishConjoint()
        }
    }

    function finishConjoint() {
        let tmpAnswers = survey.answers
        tmpAnswers.choices = choices
        addAnswers(tmpAnswers)
        setSurveyStage(SurveyStage.SWITCHING)
    }

    const [waitingTimeActive, setWaitingTimeActive] = useState<boolean>(false)
    const [seconds, setSeconds] = useState(explanationWaitingTime);
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        let interval: any = null;
        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        } else if (seconds === 0) {
            setWaitingTimeActive(true)
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    return (
        <>
            {
                showExplanation &&
                <ModalWrapper onClose={() => setShowExplanation(false)}>
                    {/*<CloseIcon onClick={() => setShowExplanation(false)}/>*/}
                    <ExplanationModal>
                        <div>
                            <Heading3>Task: Choices</Heading3>
                            <p><i>Explanation of the first task</i></p>
                            <Divider height={1}/>

                            <img src={ExplanationSrc} alt="Explanation"/>

                            <Divider/>
                            <p>
                                In the upcoming task, we will show you pairs of different
                                alternatives of services for world-wide financial transactions.
                            </p>
                            <p>
                                Each service offers different features or attributes that
                                distinguish it from other players on the market, such as transaction
                                fees or the privacy of transactions.
                            </p>
                            <p>
                                Your task is to read through each of the configurations and select
                                the alternative that is most compatible with your preferences.
                            </p>
                            <p style={{color: 'red'}}>
                                <b>
                                    Each attribute is explained at the lower end of the web page!
                                </b><br/>
                                Please make sure to read them carefully before you begin your task.
                            </p>
                        </div>
                        <Divider height={3}/>
                        <ExplanationModalFooter>
                            {
                                !waitingTimeActive &&
                                <div>
                                    Please read the instructions carefully.
                                </div>
                            }
                            <div>
                                <Button primary={true} onClick={() => {
                                    setShowExplanation(false)
                                    setSeconds(selectionWaitingTime)
                                    setWaitingTimeActive(false)
                                    window.scrollTo(0, 0);
                                }}>
                                    <span>OK, continue</span>
                                </Button>
                            </div>
                        </ExplanationModalFooter>
                    </ExplanationModal>
                </ModalWrapper>
            }
            {
                survey &&
                <>
                    <ConjointWrapper>
                        <Heading3>
                            Please select the option <u>most attractive</u> to you.
                        </Heading3>
                        <p>Detailed description of attributes below.</p>
                        <Divider height={4}/>

                        <p>Choice set <b>{choiceIdx + 1}</b> of {numChoiceSets}</p>
                        <ChoiceWrapper>
                            { waitingTimeActive &&
                                <>
                                    <ChoiceOptionWrapper>
                                        <ChoiceOption>
                                            <Heading4>
                                                Option A
                                            </Heading4>
                                            {
                                                survey.config.choice_sets[choiceIdx][0].map((val, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <div>
                                                                {getAttributeData(idx).name}
                                                            </div>
                                                            <div>
                                                                {getAttributeData(idx).values[val].value}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ChoiceOption>
                                        <Button disabled={!waitingTimeActive}  primary={true} onClick={() => {
                                            setSeconds(selectionWaitingTime)
                                            setWaitingTimeActive(false)
                                            makeChoice(0)
                                        }}>
                                            {
                                                !waitingTimeActive &&
                                                <span>Consider the options ({seconds}s)</span>
                                            }
                                            {
                                                waitingTimeActive &&
                                                <span>Select Option A</span>
                                            }
                                        </Button>
                                    </ChoiceOptionWrapper>
                                    <ChoiceOptionWrapper>
                                        <ChoiceOption>
                                            <Heading4>
                                                Option B
                                            </Heading4>
                                            {
                                                survey.config.choice_sets[choiceIdx][1].map((val, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <div>
                                                                {getAttributeData(idx).name}
                                                            </div>
                                                            <div>
                                                                {getAttributeData(idx).values[val].value}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ChoiceOption>
                                        <Button disabled={!waitingTimeActive} primary={true} onClick={() => {
                                            setSeconds(selectionWaitingTime)
                                            setWaitingTimeActive(false)
                                            makeChoice(1)
                                        }}>
                                            {
                                                !waitingTimeActive &&
                                                <span>Consider the options ({seconds}s)</span>
                                            }
                                            {
                                                waitingTimeActive &&
                                                <span>Select Option B</span>
                                            }
                                        </Button>
                                    </ChoiceOptionWrapper>
                                </>
                            }
                            { !waitingTimeActive &&
                                <WaitingScreen>
                                    Loading new choice set...
                                </WaitingScreen>
                            }
                        </ChoiceWrapper>
                    </ConjointWrapper>

                    <ExplanationWrapper>
                        <>
                            <Heading3>
                                Attribute descriptions
                            </Heading3>
                            <p>
                                Below, you find a description of the attributes that describe each
                                service that occurs in the survey. Please take your time to read
                                them carefully.
                            </p>
                            {
                                survey && survey.config.attributes.map((attribute, idx) => {
                                    return (
                                        <Explanation grey={true} key={idx}>
                                            <ExplanationHeader>
                                                <div>
                                                    <p>
                                                        <ColoredSpan>
                                                            <b>{attribute.name}</b>
                                                        </ColoredSpan>
                                                        <br/>
                                                        <span>{attribute.description}</span>
                                                    </p>
                                                </div>
                                            </ExplanationHeader>
                                            <Divider height={0.1}/>
                                            {
                                                attribute.values.map((v, idx) => {
                                                    return (
                                                        <AttributeContainer key={idx}>
                                                            <div>
                                                                <b>{v.value}:</b>
                                                            </div>
                                                            <div>
                                                                {v.explanation}
                                                            </div>
                                                        </AttributeContainer>
                                                    )
                                                })
                                            }
                                        </Explanation>
                                    )
                                })
                            }
                        </>
                    </ExplanationWrapper>
                </>
            }
        </>
    )

}


export default ConjointComponent
