import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Cookies from 'universal-cookie';
import Loading from '../../misc/Loading';

const TokenHandler: React.FC = () => {
    // @ts-ignore
    const { token, base64redirect } = useParams();
    const navigate = useNavigate();

    if (typeof token === "string") {
        localStorage.setItem('token', token);
    }

    const cookies = new Cookies();
    cookies.set('authenticated', 'true', { domain: '.pecher.co', httpOnly: false, path: '/' });

    useEffect(() => {
        if (typeof token === "string") {
            localStorage.setItem('token', token);
        }

        if (!base64redirect) {
            navigate(`/`);
        } else {
            const redirect = Buffer.from(base64redirect, 'base64').toString('utf-8');

            navigate(`${redirect}`);
        }
    }, [navigate, token, base64redirect]);

    return <Loading fullScreen />;
};

export default TokenHandler;
