import styled from "styled-components";

const ConjointWrapper = styled.div`
  max-width: 770px;
  margin: 100px auto 0 auto;
  padding: 3rem 3rem 5rem 3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.lightGrey}20;
`

const ChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 2rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.white};
  padding: 4rem 0;
  height: 365px;
`

const WaitingScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 285px;
`

const ChoiceOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  button {
    width: 90%;
  }
`

interface ChoiceOptionProps {
    borderWidth?: number
}

const ChoiceOption = styled.div<ChoiceOptionProps>`
  border-radius: ${({theme}) => theme.borderRadius2};
  border: ${(props) => props.borderWidth || 1}px solid ${({theme}) => theme.colors.lightGrey};
  background: ${({theme}) => theme.colors.white};
  min-height: 150px;
  min-width: 280px;
  padding: 0.3rem 2rem 2rem 2rem;
  
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.3rem 0;
  }
`

const ExplanationWrapper = styled.div`
  max-width: 770px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

interface ExplanationProps {
    grey?: true
}

const Explanation = styled.div<ExplanationProps>`
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.white};
  padding: 2.5rem;
  ${({grey, theme}) => {
    if (grey) {
      return `
      background: ${theme.colors.lightGrey}20;`
    } else {
      return `
      background: ${theme.colors.white};`
    }
  }}
`

const ExplanationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.1rem 0.1rem 0.5rem 0.1rem;
  margin-bottom: 0.5rem;
  gap: 2rem;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGrey}50;
  
  img {
    height: 50px;
  }
`

const AttributeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;
  margin-top: 1rem;
  
  > div:first-child {
    min-width: 80px;
  }
`

export {
    ConjointWrapper,
    ChoiceWrapper,
    ChoiceOptionWrapper,
    ChoiceOption,
    ExplanationWrapper,
    Explanation,
    ExplanationHeader,
    AttributeContainer,
    WaitingScreen
}
