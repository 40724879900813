import { Component, ReactNode } from 'react';
import ErrorComponent from './Error';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public render() {
        return this.state.hasError ? (
            <ErrorComponent
                message="Something went wrong... Please try reloading the page or contacting us."
                fullScreen
            />
        ) : (
            this.props.children
        );
    }
}

export default ErrorBoundary;
