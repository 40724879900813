import {Container, ContentWrapper, Divider, Heading1} from "../styled";
import HelloSrc from "../../assets/img/hello.svg"

const IndexComponent = () => {

    return (
        <Container>
            <ContentWrapper>
                <Divider height={15}/>

                <Heading1>
                    This is a research app
                </Heading1>
                <Divider height={1}/>
                <p>
                    If you made it to this page even though quite obviously nobody ever intended you
                    to ever come here: Greetings 🖖
                </p>
                <p>
                    This is a tool to conduct conjoint surveys used by the Chair for Cyber Trust at
                    TU Munich. It is very cool because it allows to collect a lot of tertiary data
                    on participant behavior across the tool and supports optional feedback questions
                    for testing understandability with small samples.
                </p>
                <p>
                    The tool (and this website) does not collect any data about you unless you
                    explicitly take part in one of our surveys.
                </p>
                <Divider height={1}/>
                <p>
                    To contact the system administrator write an email to:<br/>
                    <code>
                        johannes [d0t] pecher [at] tum.de
                    </code>
                </p>
                <Divider height={3}/>

                <Divider height={5}/>
                <img style={{position: 'fixed', bottom: '50px', right: '50px'}} src={HelloSrc}/>
            </ContentWrapper>
        </Container>
    )

}


export default IndexComponent
