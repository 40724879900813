import {SurveyStage} from "../components/types/types";
import {apiUrl} from "./config";

const apiUpdateStage = async (survey_id: string, stage: SurveyStage): Promise<any> => {
    try {
        const res = await fetch(apiUrl + '/survey/' + survey_id + '/stage', {
            method: 'post',
            body: JSON.stringify({
                "stage": stage
            }),
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        console.log(res)

        if (!res.ok) throw new Error();
        else {
            let text = await res.text()
            console.log(text)
            return text;
        }
    } catch (e) {
        throw new Error();
    }
};

export default apiUpdateStage;
