import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import fetchSurvey from "../../api/fetchSurvey";
import {Container, ContentWrapper} from '../styled';
import {Answers, Survey, SurveyStage} from "../types/types";
import ConsentStage from "./stage_consent/ConsentStage";
import ConjointStage from "./stage_conjoint/ConjointStage";
import SwitchingStage from "./stage_switching/SwitchingStage";
import DemographicsStage from "./stage_demographics/DemographicsStage";
import FinishedStage from "./stage_finished/FinishedStage";
import SettingStage from "./stage_setting/SettingStage";
import apiUpdateAnswers from "../../api/updateAnswers";
import apiUpdateStage from "../../api/updateStage";


const SurveyComponent = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const {pathname} = useLocation();
    const params = useParams()

    const [survey, setSurvey] = useState<Survey | null>(null);
    const [stage, setStage] = useState<SurveyStage>(SurveyStage.CONSENT)
    const [redirect, setRedirect] = useState<string>("")

    const fetchData = async (survey_id: string) => {
        try {
            setLoading(true)
            let c = await fetchSurvey(survey_id)
            setSurvey(c);
        } catch (e) {
            setError('An unexpected error occurred, please try reloading the page or contacting us.')
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const stateChanged =
            (survey && survey.stage !== stage) || !survey
        if (params.survey_id && stateChanged) {
            fetchData(params.survey_id).catch(() => {
            })
        }
        return
    }, [])

    const setSurveyStage = async (newStage: SurveyStage) => {
        if (survey) {
            window.scrollTo(0, 0);
            let tmpSurvey = survey
            tmpSurvey.stage = newStage
            await apiUpdateStage(survey.survey_id, survey.stage)
            setSurvey(tmpSurvey)
            setStage(newStage)
        }
    }

    const addAnswers = async (answers: Answers) => {
        if (survey) {
            let tmpSurvey = survey
            tmpSurvey.answers = answers
            setSurvey(tmpSurvey)
            await apiUpdateAnswers(survey.survey_id, survey.answers)
        }
    }

    return (
        <Container>
            <ContentWrapper>
                <>
                    {
                        survey?.stage === SurveyStage.CONSENT &&
                        <ConsentStage setSurveyStage={setSurveyStage} survey={survey}/>
                    }
                    {
                        survey?.stage === SurveyStage.SETTING &&
                        <SettingStage setSurveyStage={setSurveyStage} survey={survey}/>
                    }
                    {
                        survey?.stage === SurveyStage.CONJOINT &&
                        <ConjointStage setSurveyStage={setSurveyStage} addAnswers={addAnswers} survey={survey}/>
                    }
                    {
                        survey?.stage === SurveyStage.SWITCHING &&
                        <SwitchingStage setSurveyStage={setSurveyStage} addAnswers={addAnswers} survey={survey}/>
                    }
                    {
                        survey?.stage === SurveyStage.DEMOGRAPHICS &&
                        <DemographicsStage setSurveyStage={setSurveyStage} addAnswers={addAnswers} survey={survey}/>
                    }
                    {
                        survey?.stage === SurveyStage.FINISHED &&
                        <FinishedStage setSurveyStage={setSurveyStage} survey={survey} redirect={redirect}/>
                    }
                </>
            </ContentWrapper>
        </Container>
    );
};

export default SurveyComponent;
