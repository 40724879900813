import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-family: ${({theme}) => theme.fontFamily};
    font-size: 10px;
    scroll-behavior: smooth;
  }

  body {
    font-size: 1.6rem;
    min-width: 800px; // conjoint selection should not be influenced by display hierarchy
  }

  #root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    background-color: ${({theme}) => theme.colors.white};
    color: ${({theme}) => theme.colors.black};
  }

  img {
    max-width: 100%;
  }

  a {
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }

  p {
    line-height: 1.35;
    margin-bottom: 0.7rem;
  }

  code {
    font-size: 90%;
  }

  input[type=date], textarea, button {
    font-family: ${({theme}) => theme.fontFamily};
  }
`;

export default GlobalStyle;
