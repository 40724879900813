import {Section} from "./types";

export const testSections: Section[] = [
    {
        "name": "Data sharing",
        "subtitle": "We want to understand how you think about data sharing",
        "index": 0,
        "questions": [
            {
                "id": "DATA_000",
                "type": "SINGLE_CHOICE",
                "text": "Which of the following characterization describes you best in terms of data sharing?",
                options: [
                    "I share my data freely and am not concerned about its use.",
                    "I share my data freely but I am concerned about its use.",
                    "I am not very concerned about the usage of my data but try to keep a good balance.",
                    "I try to limit the number of services I share my data with to what is necessary.",
                    "I only use select online services because of privacy concerns.",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "DATA_001",
                "type": "SINGLE_CHOICE",
                "text": "Did you previously use payment providers like Paypal, Amazon Pay or Google Pay when making an online purchase?",
                options: [
                    "Yes, I did.",
                    "No, I didn't."
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "DATA_002",
                "type": "MULTIPLE_CHOICE",
                "text": "Have you made an international money transfer?",
                options: [
                    "With a classical financial transaction via your bank.",
                    "With an alternative payment provider such as Wise.",
                    "Using cryptocurrency.",
                    "I never made an international money transfer."
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            }
        ]
    },
    {
        "name": "Experience with crypto currencies",
        "subtitle":"",
        "index": 1,
        "questions": [
            {
                "id": "CRYPTO_000",
                "type": "SINGLE_CHOICE",
                "text": "What is your affinity with cryptocurrency (e.g., Bitcoin or Ethereum)",
                options: [
                    "I have never heard of cryptocurrencies.",
                    "I have never owned any cryptocurrency.",
                    "I have owned or own some small amount of cryptocurrency.",
                    "I am very engaged in cryptocurrencies and have a considerable stake of my assets invested in them.",
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "CRYPTO_001",
                "type": "SINGLE_CHOICE",
                "text": "What is your affinity with NFTs (non-fungible token), e.g., to testify ownership in certain assets such as a digital artwork.",
                options: [
                    "I have never heard of NFTs.",
                    "I have never owned any NFTs.",
                    "I have owned or own a small number of NFTs.",
                    "I am very engaged in NFTs and have a considerable stake of my assets invested in them.",
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            }
        ]
    },
    {
        "name": "Preferences (1/8)",
        "subtitle":"",
        "index": 2,
        "questions": [
            {
                "id": "TX_000",
                "type": "LIKERT_SCALE",
                "text": "I compare prices almost always before I buy something.",
            },
            {
                "id": "TX_001",
                "type": "LIKERT_SCALE",
                "text": "I am less willing to use an online service if I think that it will be high in price.",
            },
            {
                "id": "TX_002",
                "type": "LIKERT_SCALE",
                "text": "In general, the price or cost of a new online service is important to me.",
            },
            {
                "id": "TX_003",
                "type": "LIKERT_SCALE",
                "text": "A secure online service is worth paying a premium fee.",
            },
            {
                "id": "TX_004",
                "type": "LIKERT_SCALE",
                "text": "An online service that puts emphasis on privacy is worth paying a lot of money for.",
            },
            {
                "id": "TX_005",
                "type": "LIKERT_SCALE",
                "text": "A well-designed online service is worth paying a lot of money for.",
            }
        ]
    },
    {
        "name": "Preferences (2/8)",
        "subtitle":"",
        "index": 3,
        "questions": [
            {
                "id": "TXP_000",
                "type": "LIKERT_SCALE",
                "text": "It is very important to me that I am aware and knowledgeable about how my personal information will be used.",
            },
            {
                "id": "TXP_001",
                "type": "LIKERT_SCALE",
                "text": "When mobile apps ask me for personal information, I sometimes think twice before providing it.",
            },
            {
                "id": "TXP_002",
                "type": "LIKERT_SCALE",
                "text": "Compared to others, I am more sensitive about the way mobile app providers handle my personal information.",
            },
            {
                "id": "TXP_003",
                "type": "LIKERT_SCALE",
                "text": "To me, it is the most important thing to keep my privacy intact from app providers.",
            },
            {
                "id": "TXP_004",
                "type": "LIKERT_SCALE",
                "text": "I have had a bad experience due to data breaches.",
            },
            {
                "id": "TXP_005",
                "type": "LIKERT_SCALE",
                "text": "My identity has been abused by scammers/criminals before.",
            }
        ]
    },
    {
        "name": "Preferences (3/8)",
        "subtitle":"",
        "index": 4,
        "questions": [
            {
                "id": "OBT_000",
                "type": "LIKERT_SCALE",
                "text": "I frequently switch waiting lines in front of counters.",
            },
            {
                "id": "OBT_001",
                "type": "LIKERT_SCALE",
                "text": "I am often annoyed when I have to wait for a process to complete.",
            },
            {
                "id": "OBT_002",
                "type": "LIKERT_SCALE",
                "text": "I reload websites when loading times are too long.",
            },
            {
                "id": "OBT_003",
                "type": "LIKERT_SCALE",
                "text": "I tend to abort a signup process when I have to fill in many input fields.",
            },
            {
                "id": "OBT_004",
                "type": "LIKERT_SCALE",
                "text": "I have no problems filling in a lot of forms before getting accepted into a program or service.",
            },
            {
                "id": "OBT_005",
                "type": "LIKERT_SCALE",
                "text": "I consider online authentication processes (video identification or security requirements for passwords) a burden when signing up for an online service.",
            }
        ]
    },
    {
        "name": "Preferences (4/8)",
        "subtitle":"",
        "index": 5,
        "questions": [
            {
                "id": "OWN_000",
                "type": "LIKERT_SCALE",
                "text": "Stock and related products such as ETFs are a considerable part of my pension scheme.",
            },
            {
                "id": "OWN_001",
                "type": "LIKERT_SCALE",
                "text": "I prefer owning to renting real-estate.",
            },
            {
                "id": "OWN_002",
                "type": "LIKERT_SCALE",
                "text": "I don’t want to be tied to things by ownership.",
            },
            {
                "id": "OWN_003",
                "type": "LIKERT_SCALE",
                "text": "I fear the responsibility that comes with property.",
            },
            {
                "id": "OWN_004",
                "type": "LIKERT_SCALE",
                "text": "I admire business owners.",
            },
            {
                "id": "OWN_005",
                "type": "LIKERT_SCALE",
                "text": "I would rather buy a car than invest in stocks.",
            }
        ]
    },
    {
        "name": "Preferences (5/8)",
        "subtitle":"",
        "index": 6,
        "questions": [
            {
                "id": "COM_000",
                "type": "LIKERT_SCALE",
                "text": "I enjoy being part of active communities with active discussion and mutual help.",
            },
            {
                "id": "COM_001",
                "type": "LIKERT_SCALE",
                "text": "At home, I know all my neighbors by name and have a good relationship with them.",
            },
            {
                "id": "COM_002",
                "type": "LIKERT_SCALE",
                "text": "I have never engaged in any community.",
            },
            {
                "id": "COM_003",
                "type": "LIKERT_SCALE",
                "text": "I contribute to social initiatives.",
            },
            {
                "id": "COM_004",
                "type": "LIKERT_SCALE",
                "text": "The community of my family is very important to me.",
            },
            {
                "id": "COM_005",
                "type": "LIKERT_SCALE",
                "text": "The friendships I have are an important part of my life and knowing that I have them gives me security.",
            }
        ]
    },
    {
        "name": "Preferences (6/8)",
        "subtitle":"",
        "index": 7,
        "questions": [
            {
                "id": "SWI_000",
                "type": "LIKERT_SCALE",
                "text": "I usually stick with my telephone/mobile provider for years without changing the contract. ",
            },
            {
                "id": "SWI_001",
                "type": "LIKERT_SCALE",
                "text": "I am regularly switching my utility provider at home because I want to minimize costs.",
            },
            {
                "id": "SWI_002",
                "type": "LIKERT_SCALE",
                "text": "I am often changing the brand of my smartphone.",
            },
            {
                "id": "SWI_003",
                "type": "LIKERT_SCALE",
                "text": "I usually change my employer once every two year interval.",
            },
            {
                "id": "SWI_004",
                "type": "LIKERT_SCALE",
                "text": "I often think about switching my phone provider.",
            },
            {
                "id": "SWI_005",
                "type": "LIKERT_SCALE",
                "text": "I often think about switching between Apple and Android.",
            },
            {
                "id": "SWI_006",
                "type": "LIKERT_SCALE",
                "text": "Switching from Apple to Android or vice versa does not feel difficult for me.",
            }
        ]
    },
    {
        "name": "Preferences (7/8)",
        "subtitle":"",
        "index": 8,
        "questions": [
            {
                "id": "IMP_000",
                "type": "LIKERT_SCALE",
                "text": "I usually plan tasks carefully.",
            },
            {
                "id": "IMP_001",
                "type": "LIKERT_SCALE",
                "text": "I usually plan trips well ahead of time.",
            },
            {
                "id": "IMP_002",
                "type": "LIKERT_SCALE",
                "text": "Job security is highly important for me.",
            },
            {
                "id": "IMP_003",
                "type": "LIKERT_SCALE",
                "text": "I often change jobs.",
            },
            {
                "id": "IMP_004",
                "type": "LIKERT_SCALE",
                "text": "I often buy things on impulse.",
            },
            {
                "id": "IMP_005",
                "type": "LIKERT_SCALE",
                "text": "I often change hobbies.",
            }
        ]
    },
    {
        "name": "Preferences (8/8)",
        "subtitle":"",
        "index": 9,
        "questions": [
            {
                "id": "TEC_000",
                "type": "LIKERT_SCALE",
                "text": "I am very curious about new technological developments.",
            },
            {
                "id": "TEC_001",
                "type": "LIKERT_SCALE",
                "text": "I quickly take a liking to new technological developments.",
            },
            {
                "id": "TEC_002",
                "type": "LIKERT_SCALE",
                "text": "I am always interested in using the latest technological devices.",
            },
            {
                "id": "TEC_003",
                "type": "LIKERT_SCALE",
                "text": "If I had the opportunity, I would use technological products much more often than I do at present.",
            },
            {
                "id": "TEC_004",
                "type": "LIKERT_SCALE",
                "text": "When dealing with modern technology, I am often afraid of failing.",
            },
            {
                "id": "TEC_005",
                "type": "LIKERT_SCALE",
                "text": "For me, dealing with technological innovations is usually too much of a challenge.",
            },
            {
                "id": "TEC_006",
                "type": "LIKERT_SCALE",
                "text": "I am afraid of breaking new technological developments rather than using them properly.",
            },
            {
                "id": "TEC_007",
                "type": "LIKERT_SCALE",
                "text": "I find dealing with new technology difficult - I simply can’t do it most of the time.",
            }
        ]
    },
    {
        "name": "Personal information",
        "subtitle": "We use this data to compare your answers with others and make inductions about demographic impacts on the choices you made.",
        "index": 10,
        "questions": [
            {
                "id": "P_000",
                "type": "SINGLE_CHOICE",
                "text": "Specify which best fits your background:",
                options: [
                    "Technical background (Engineering, Software, or similar)",
                    "Business background (Management, MBA)",
                    "(Applied) Creative background (Designer, Writer, etc.)",
                    "Humanities background (Art, History, Philosophy, Math, ...)",
                    "Other",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_001",
                "type": "SINGLE_CHOICE",
                "text": "What is your age?",
                options: [
                    "18 - 22",
                    "23 - 30 years old",
                    "30 - 45 years old",
                    "45+",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_002",
                "type": "SINGLE_CHOICE",
                "text": "What is your gender?",
                options: [
                    "Female",
                    "Male",
                    "Other",
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_003",
                "type": "SINGLE_CHOICE",
                "text": "What is your level of education?",
                options: [
                    "Less than a high school diploma",
                    "High school degree or equivalent",
                    "Bachelor's degree",
                    "Master's degree or diploma",
                    "Ph.D. or higher",
                    "Other",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_004",
                "type": "SINGLE_CHOICE",
                "text": "Which region are you from?",
                options: [
                    "North America/Central America",
                    "South America",
                    "Europe",
                    "Africa",
                    "Asia",
                    "Australia",
                    "Other",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_005",
                "type": "SINGLE_CHOICE",
                "text": "What is your current employment status?",
                options: [
                    "Employed full-time",
                    "Employed part-time",
                    "Unemployed (currently looking for work)",
                    "Student",
                    "Retired",
                    "Self-employed",
                    "Unable to work",

                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_006",
                "type": "SINGLE_CHOICE",
                "text": "What is your current marital status?",
                options: [
                    "Single (never married)",
                    "Married",
                    "In a domestic partnership",
                    "Divorced",
                    "Widowed",
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            },
            {
                "id": "P_007",
                "type": "SINGLE_CHOICE",
                "text": "What is your current employment status?",
                options: [
                    "Below €20,000",
                    "€20,000 - €50,000",
                    "€50,000 - €100,000",
                    "€100,000 - €150,000",
                    "More than €150,000",
                ],
                "annotation": "",
                "placeholder": "",
                "text_answer": ""
            }
        ]
    },
    {
        "name": "Understandability",
        "subtitle": "Just to check if you got everything right.",
        "index": 11,
        "questions": [
            {
                "id": "FDB_000",
                "type": "LIKERT_SCALE",
                "text": "All attributes descriptions were easily understandable."
            },
            {
                "id": "FDB_001",
                "type": "LIKERT_SCALE",
                "text": "The setting of the survey was easy to understand."
            },
            {
                "id": "FDB_002",
                "type": "LIKERT_SCALE",
                "text": "The description of the first task (\"Choices\") was clear and I could follow the instructions."
            },
            {
                "id": "FDB_003",
                "type": "TEXT",
                "text": "What was it you didn't understand about the first task?",
                "annotation": "Leave blank if you didn't have issues understanding"
            },
            {
                "id": "FDB_004",
                "type": "LIKERT_SCALE",
                "text": "The description of the second task (“Stay or Switch”) was clear and I could follow the instructions."
            },
            {
                "id": "FDB_005",
                "type": "TEXT",
                "text": "What was it you didn’t understand about the second task? (Leave blank if you didn’t have issues understanding).",
                "annotation": "Leave blank if you didn't have issues understanding"
            },
            {
                "id": "FDB_006",
                "type": "SINGLE_CHOICE",
                "text": "Transaction fees are...",
                options: [
                    "... money that a service provider charges me when I send money to another account.",
                    "... are fees to subscribe to a transaction provider."
                ]
            },
            {
                "id": "FDB_007",
                "type": "MULTIPLE_CHOICE",
                "text": "Please select the attributes that you were UNABLE to understand. If you understood everything well, please select the last option.",
                options: [
                    "Transaction Fees",
                    "Transaction Time",
                    "Transaction Privacy",
                    "Onboarding Time",
                    "Ownership",
                    "Community",
                    "I understood everything",
                ]
            },
            {
                "id": "FDB_008",
                "type": "TEXT",
                "text": "Why unable to understand the selected attributes?",
                "annotation": "Leave blank if you didn't have issues understanding"
            },
            {
                "id": "FDB_009",
                "type": "TEXT",
                "text": "Do you have any further comments or feedback regarding the study?",
                "annotation": "Leave blank if you don't have any comment or feedback."
            },
        ]
    }
]
