import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;

  @media (max-width: 1300px) {
    width: 90%;
  }
`;

const CenteredContainer = styled.div`
  max-width: 770px;
  margin: 100px auto 0 auto;
  padding: 3rem;
  border-radius: ${({theme}) => theme.borderRadius2};
  background: ${({theme}) => theme.colors.lightGrey}20;
`

const ContentWrapper = styled.div`
  padding: 5rem 0;
`;

interface DividerProps {
    height?: number
}
const Divider = styled.div<DividerProps>`
  height: ${(props) => props.height || 2}rem;
`

const ProductWrapper = styled.div`
  margin-bottom: 5rem;

  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;

interface FlexWrapperProps {
    orientation?: string;
}

const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: ${(props) => props.orientation || 'row'};
`;


const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem 1rem;
  border: 2px solid ${({theme}) => theme.colors.black};
  border-radius: ${({theme}) => theme.borderRadius2};
`

export {
    Container,
    CenteredContainer,
    ContentWrapper,
    ProductWrapper,
    FlexWrapper,
    Card,
    Divider
}
